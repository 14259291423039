export default {
  general: {
    username: 'Nombre de Usuario',
    password: 'Contraseña',
    newpass: 'Nueva contraseña',
    currentpass: 'Contraseña actual',
    c_password: 'Confirmar contraseña',
    c_newpass: 'Confirmar nueva contraseña',
    email: 'Dirección de email',
    language: 'Idioma',
    subject: 'Asunto',
    message: 'Mensaje',
    filtername: 'Nombre del filtro',
    placeholders: {
      username: 'Introduce nombre de usuario',
      password: 'Introduce contraseña',
      newpass: 'Introduce nueva contraseña',
      currentpass: 'Introduce contraseña actual',
      c_password: 'Confirma contraseña',
      c_newpass: 'Confirma nueva contraseña',
      email: 'Introduce email',
      subject: 'Introduce asunto',
      message: 'Introduce tu mensaje',
      filtername: 'Introduce un nombre'
    },
    errors: {
      language: {
        load: 'Fallo al cambiar de idioma.'
      }
    },
    here: 'aquí',
    ok: 'Ok',
    cancel: 'Cancelar',
    on: 'Sí',
    off: 'No',
    min: 'Valor mínimo',
    max: 'Valor máximo',
    years: '{n} año | {n} años',
    days: '{n} día | {n} días',
    type_for_more: 'Escribe para ver más...'
  },
  notifications: {
    disabled: 'Las {notifications} están bloqueadas. Si no las bloqueaste, considera usar otro navegador.',
    notifications: 'notificaciones',
    title: 'Configuración de Notificaciones',
    form: {
      email: 'Notificaciones por email',
      email_frequency: 'Frecuencia de notificaciones por email',
      browser: 'Notificaciones por navegador',
      browser_frequency: 'Frecuencia de notificaciones por el navegador',
      frequency: 'al instante | una vez cada {n} hora | una vez cada {n} horas',
      help: {
        email: 'Recibirás notificaciones de jugadores interesantes vía email.',
        browser: 'Recibirás jugadores interesantes directamente en tu navegador.',
        frequency: 'El valor recomendado es \'al instante\' para evitar perder algunos jugadores.',
        blocked: 'Has bloqueado las notificaciones o tu navegador no las sorporta.'
      }
    }
  },
  header: {
    menu: {
      home: 'Inicio',
      account: 'Cuenta',
      signin: 'Accede',
      register: 'Registro',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Contáctenos',
      logout: 'Desconecta'
    }
  },
  footer: {
    menu: {
      privacy: 'Privacidad (Inglés)',
      terms: 'Condiciones (Inglés)'
    }
  },
  login: {
    title: 'Accede',
    signin: 'Accede',
    register: 'Registro',
    forgot: '¿Olvidaste tu contraseña?',
    form: {
      submit: 'Accede',
      remember: 'Recordar mis datos'
    },
    info: {
      what: '¿Qué hace Mercattrick?',
      purpose: 'El objetivo de Mercattrick es:',
      purpose1: 'ayudar a los <strong>managers</strong> de Hattrick que <strong>venden</strong> jugadores a conseguir mayor exposición y así conseguir más dinero en la venta;',
      purpose2: 'ayudar a <strong>managers</strong> que están <strong>interesados en comprar</strong> ciertos tipos de jugadores a recibir notificaciones cuando jugadores interesantes han sido añadidos a la lista de transferencias;',
      how: '¿Cómo lo hace?',
      buyers: 'Compradores',
      step1: 'inicia sesión y configura el mínimo de características de un jugador (filtros) para los que quieres ser notificado cuando salgan a la venta.',
      sellers: 'Vendedores',
      step2: 'pon jugadores en venta en HT, luego inicia sesión y "publicita" esos jugadores.',
      promoting: 'Publicita',
      step3: 'un jugador significa que todos los "compradores" interesados en ese tipo de jugadores serán notificados de que está en venta.'
    }
  },
  register: {
    title: 'Registro',
    form: {
      registered: 'Tu cuenta ha sido creada. Haz click {here} para continuar.',
      submit: 'Registro',
      agreement: 'Haciendo click @:register.form.submit estás de acuerdo en las {terms} y {privacy} y en recibir ocasionalmente emails sobre tu cuenta.',
      tos: 'condiciones del servicio',
      privacy: 'declaración de privacidad',
      help: {
        username: 'No es tu nombre de usuario en HT.',
        password: 'Introduce un mínimo de 6 caracteres. La contraseña distingue entre mayúsculas y minúsculas.',
        email: 'Usaremos este email para notificarte de nuevos jugadores.'
      }
    }
  },
  recover: {
    title: 'Recuperar Contraseña',
    form: {
      recovered: 'Se ha enviado un enlace de restablecimiento de contraseña a su email.',
      submit: 'Recuperar',
      help: {
        email: 'Este debe ser el email asociado con tu cuenta.'
      }
    }
  },
  reset: {
    title: 'Restablecer Contraseña',
    form: {
      submit: 'Restablecer',
      help: {
        email: 'Este debe ser el email asociado con tu cuenta.',
        password: 'Introduce un mínimo de 6 caracteres. La contraseña distingue entre mayúsculas y minúsculas.'
      }
    }
  },
  contact: {
    title: 'Contacto',
    form: {
      success: 'El mensaje ha sido enviado satisfactoriamente.',
      submit: 'Enviar',
      help: {
        email: 'Usaremos este email sólo para contestarte.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Sincronizando detalles de usuario...',
      fail: 'Sincronización de usuario errónea.',
      retry: 'Inténtalo de nuevo',
      authorize: 'Necesitas autorizar este producto CHPP. Haz click {here} para abrir la web de Hattrick.',
      authorizing: 'Iniciando la autorización. Serás redirigido a <strong>hattrick.org</strong> en breve...',
      finalize: 'Finalizando la autorización...'
    },
    system: {
      syncing: 'Sincronizando detalles del sistema...',
      fail: 'Sincronización del sistema errónea. {retry} o {skip}!',
      retry: 'Inténtalo de nuevo',
      skip: 'Saltar'
    }
  },
  account: {
    account: {
      title: 'Configuración de tu Cuenta',
      form: {
        success: 'Tu cuenta se ha actualizado satisfactoriamente.',
        submit: 'Guardar',
        help: {
          username: 'Este no es tu usario de HT.',
          email: 'Usaremos este email para notificarte de nuevos jugadores.'
        }
      }
    },
    login: {
      title: 'Configuración de Inicio de Sesión',
      form: {
        success: 'Tu contraseña se ha actualizado satisfactoriamente.',
        submit: 'Guardar',
        help: {
          passwords: 'Escrine tu contraseña actual, tu nueva contraseña y la confirmación de tu nueva contraseña.'
        }
      }
    },
    privacy: {
      title: 'Configuración de Privacidad',
      form: {
        submit: 'Borrar Cuenta',
        delete: 'Borrar',
        help: {
          warning: 'Esta acción es permanente. Todos tus datos serán borrados.',
          confirmation: 'Confirma que aceptas que todos tus datos serán eliminados.'
        }
      }
    }
  },
  filter: {
    title: 'Toca para agregar/eliminar un filtro',
    form: {
      submit: 'Guardar',
      set: 'Configurar',
      remove: 'Eliminar',
      help: {
        name: 'Da un nombre a tu filtro para identicarlo.'
      }
    },
    tooltip: {
      save: '¡Es necesario establecer al menos un criterio para guardar el filtro!'
    },
    item: {
      tooltip: 'Haz click \'@:filter.item.show_players\' para mostrar la lista.',
      show_players: 'Mostrar Jugadores',
      hide_players: 'Ocultar Jugadores',
      players: 'jugador encontrado | jugadores encontrados',
      form: {
        processing: 'procesando',
        fetch: 'Traer jugadores de HT',
        edit: 'Editar',
        delete: 'Borrar',
        help: {
          confirm: '¿Estás seguro de que quieres eliminar este filtro?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Mis Transferencias',
      loading: 'Cargando jugadores...',
      none: 'No tienes jugadores en venta en este momento.',
      promoted: 'promocionados',
      late: 'muy tarde para promocionar',
      form: {
        reload: 'Recargar',
        promote: 'Promocionar'
      },
      tooltip: 'Promociona tus jugadores para que otros usuarios puedan verlos.'
    },
    bookmarks: {
      title: 'Favoritos',
      loading: 'Cargando jugadores...',
      none: 'No tienes jugadores favoritos en este momento.',
      form: {
        reload: 'Recargar',
        unbookmark: 'Desmarca',
        help: {
          confirm: '¿Estás seguro de que quieres eliminar este favorito?'
        }
      },
      tooltip: 'Recibirás notificaciones sobre esos jugadores cuando estén cerca de terminar sus pujas.'
    },
    filters: {
      title: 'Mis Filtros',
      loading: 'Cargando filtros...',
      none: 'No tienes filtros, deberías crear uno.',
      form: {
        reload: 'Recargar',
        add: 'Añadir',
        bookmark: 'Marca',
        hide: 'Esconder',
        help: {
          confirm: '¿Estás seguro de que quieres ocultar este jugador?'
        }
      },
      tooltip: 'Añade a favoritos jugadores interesantes para esas listas.'
    }
  },

  player: {
    attributes: {
      name: 'Nombre',
      age: 'Edad',
      form: 'Forma',
      experience: 'Experiencia',
      leadership: 'Liderazgo',
      stamina_skill: 'Resistencia',
      keeper_skill: 'Porteria',
      playmaker_skill: 'Jugadas',
      scorer_skill: 'Anotación',
      passing_skill: 'Pases',
      winger_skill: 'Lateral',
      defender_skill: 'Defensa',
      set_pieces_skill: 'Balón parado',
      agreeability: 'Carácter',
      aggressiveness: 'Agresividad',
      honesty: 'Honestidad',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Salario',
      price: 'Precio',
      asking_price: 'Precio solicitado',
      highest_bid: 'Oferta más alta',
      native_country: 'País de origen',
      native_country_id: 'País de origen',
      NT_player: 'Jugador en la Selección Nacional',
      specialties: 'Especialidades',
      specialty: 'Especialidad'
    },
    redcard: 'Tarjeta Roja',
    yellowcard: 'Tarjeta Amarilla',
    bruised: 'Lastimado',
    injured: 'Lesionado',
    injury: '{n} semana | {n} semanas',
    fullage: '{years} años y {days} días',
    deadline: 'Fin de la puja',
    htms: 'HTMS/HTMS28',
    more: 'Mostrar más',
    less: 'Mostrar menos',
    levels: {
      skill: [
        'nulo',
        'desastroso',
        'horrible',
        'pobre',
        'débil',
        'insuficiente',
        'aceptable',
        'bueno',
        'excelente',
        'formidable',
        'destacado',
        'brillante',
        'magnífico',
        'clase mundial',
        'sobrenatural',
        'titánico',
        'extraterrestre',
        'mítico',
        'mágico',
        'utópico',
        'divino',
        'divino (+1)',
        'divino (+2)',
        'divino (+3)',
        'divino (+4)',
        'divino (+5)'
      ],
      agreeability: [
        'antipático',
        'polémico',
        'agradable',
        'carismático',
        'popular',
        'querido compañero de equipo'
      ],
      aggressiveness: [
        'tranquilo',
        'calmado',
        'equilibrado',
        'temperamental',
        'iracundo',
        'violento'
      ],
      honesty: [
        'infame',
        'deshonesto',
        'honesto',
        'justo',
        'honorable',
        'santito'
      ],
      specialty: [
        'ninguna',
        'técnico',
        'rápido',
        'potente',
        'imprevisible',
        'cabeceador',
        'estoico',
        '',
        'influyente'
      ],
      national_team: [
        'nunca',
        'actualmente no',
        'actualmente sí'
      ],
      any: 'cualquiera/ninguna'
    },
    tooltips: {
      highest_bid: '¡La mayor oferta puede estar desactualizada!'
    }
  },
  countries: {
    1: 'Suecia',
    2: 'Inglaterra',
    3: 'Alemania',
    4: 'Italia',
    5: 'Francia',
    6: 'México',
    7: 'Argentina',
    8: 'USA',
    9: 'Noruega',
    10: 'Dinamarca',
    11: 'Finlandia',
    12: 'Países Bajos',
    13: 'Oceanía',
    14: 'Canadá',
    15: 'Escocia',
    16: 'Irlanda',
    17: 'Chile',
    18: 'Colombia',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Perú',
    22: 'Brasil',
    23: 'Portugal',
    24: 'Sudáfrica',
    25: 'Japón',
    26: 'Polonia',
    27: 'India',
    28: 'China',
    29: 'Corea del Sur',
    30: 'Tailandia',
    31: 'Turquía',
    32: 'Egipto',
    33: 'Austria',
    34: 'Rusia',
    35: 'España',
    36: 'Rumania',
    37: 'Islandia',
    38: 'Bélgica',
    39: 'Malasia',
    40: 'Suiza',
    41: 'Singapur',
    42: 'Croacia',
    43: 'Serbia',
    44: 'Hungría',
    45: 'Grecia',
    46: 'República Checa',
    47: 'Estonia',
    48: 'Letonia',
    49: 'Indonesia',
    50: 'Filipinas',
    51: 'Israel',
    52: 'Taipei Chino',
    53: 'Hong Kong',
    55: 'Bulgaria',
    56: 'Gales',
    57: 'Eslovenia',
    61: 'Lituania',
    62: 'Ucrania',
    63: 'Bosnia y Herzegovina',
    64: 'Pakistán',
    65: 'Vietnam',
    66: 'Eslovaquia',
    67: 'Paraguay',
    68: 'Ecuador',
    69: 'Bolivia',
    70: 'Nigeria',
    71: 'Islas Feroe',
    72: 'Marruecos',
    75: 'Arabia Saudita',
    76: 'Túnez',
    77: 'Costa Rica',
    78: 'Emiratos Árabes Unidos',
    79: 'Luxemburgo',
    80: 'Irán',
    82: 'Chipre',
    83: 'República Dominicana',
    86: 'Senegal',
    87: 'Belarus',
    88: 'Irlanda del Norte',
    89: 'Jamaica',
    90: 'Kenia',
    91: 'Panamá',
    92: 'Macedonia',
    93: 'Cuba',
    94: 'Albania',
    95: 'Honduras',
    96: 'El Salvador',
    97: 'Malta',
    98: 'Kirguistán',
    99: 'Moldova',
    100: 'Georgia',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Jordan',
    104: 'Armenia',
    105: 'Trinidad & Tobago',
    121: 'Nicaragua',
    122: 'Kazajstán',
    123: 'Surinam',
    125: 'Liechtenstein',
    126: 'Argelia',
    127: 'Mongolia',
    128: 'Líbano',
    129: 'Bahrein',
    130: 'Barbados',
    131: 'Cabo Verde',
    132: 'Costa de Marfil',
    133: 'Azerbaiyán',
    134: 'Kuwait',
    135: 'Iraq',
    136: 'Montenegro',
    137: 'Angola',
    138: 'Bangladesh',
    139: 'Yemen',
    140: 'Omán',
    142: 'Mozambique',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Camboya',
    147: 'Benin',
    148: 'Siria',
    149: 'Qatar',
    150: 'Tanzania',
    153: 'Uganda',
    154: 'Maldivas',
    163: 'Uzbekistán',
    165: 'Camerún',
    166: 'Palestina',
    175: 'Sri Lanka',
    177: 'Santo Tomé y Príncipe',
    178: 'Curacao',
    179: 'Guam',
    180: 'Comoras',
    181: 'RD Congo',
    182: 'Etiopía',
    183: 'Madagascar',
    184: 'San Vicente y las Granadinas',
    185: 'Botswana',
    186: 'Belice',
    187: 'Zambia',
    188: 'Haití',
    189: 'Myanmar',
    190: 'Puerto Rico',
    191: 'San Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Granada',
    196: 'Tahití',
    197: 'Guyana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Este campo debe contener sólo caracteres alfanuméricos.',
      alpha_dash: 'Este campo debe contener sólo caracteres alfanuméricos, palabras subrayadas y guiones.',
      confirmed: 'La confirmación no coincide.',
      email: 'Debe ser una dirección de email correcta.',
      max: 'Este campo contiene demasiados carácteres.',
      min: 'Este campo contiene demasiado pocos carácteres.',
      required: 'Campo obligatorio.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Titulo obligatorio.',
        body: 'Contenido obligatorio.',
        username: 'Usuario obligatorio.',
        password: 'Contraseña obligatoria.',
        locale: 'Idioma obligatorio.',
        name: 'Nombre obligatorio.',
        token: 'El token es obligatorio.'
      },
      integer: {
        native_country_id: 'El identificador de país debe ser un número.',
        htms_min: 'El valor mínimo de HTMS tiene que ser un número.',
        htms_max: 'El valor máximo de HTMS tiene que ser un número.',
        htms28_min: 'El valor mínimo de HTMS28 tiene que ser un número.',
        htms28_max: 'El valor máximo de HTMS28 tiene que ser un número.',
        tsi_min: 'El valor mínimo de TSI tiene que ser un número.',
        tsi_max: 'El valor máximo de TSI tiene que ser un número.'
      },
      numeric: {
        age_min: 'La edad mínima debe ser un número.',
        age_max: 'La edad máxima debe ser un número.',
        salary_min: 'El salario mínimo debe ser un número.',
        salary_max: 'El salario máximo debe ser un número.',
        price_min: 'El valor de precio mínimo tiene que ser un número.',
        price_max: 'El valor de precio máximo tiene que ser un número.'
      },
      required: {
        subject: 'Título obligatorio.',
        body: 'Contenido obligatorio.',
        username: 'Usuario obligatorio.',
        password: 'Contraseña obligatoria.',
        current_password: 'Se requiere la contraseña actual.',
        new_password: 'Nueva contraseña obligatoria.',
        email: 'Email obligatorio.',
        token: 'El token es obligatorio.',
        endpoint: 'The \'endpoint\' parámetro obligatorio.',
        url: 'The \'URL\' parámetro obligatorio.',
        oauth_token: 'The \'oauth_token\' parámetro obligatorio.',
        oauth_verifier: 'The \'oauth_verifier\' parámetro obligatorio.'
      },
      max: {
        age_min: 'La edad mínima debe ser inferior a 100.',
        age_max: 'La edad máxima debe ser inferior a 100.',
        htms_min: 'El HTMS mínimo debe ser inferior a 12.000.',
        htms_max: 'El HTMS máximo debe ser inferior a 12.000.',
        htms28_min: 'El HTMS28 mínimo debe ser inferior a 12.000.',
        htms28_max: 'El HTMS28 máximo debe ser inferior a 12.000.',
        tsi_min: 'El TSI mínimo debe ser inferior a 16.000.000.',
        tsi_max: 'El TSI máximo debe ser inferior a 16.000.000.',
        subject: 'El título es demasiado largo.',
        username: 'El usuario es demasiado largo.',
        email: 'El email es demasiado largo.',
        locale: 'El idioma es demasiado largo.',
        name: 'Nombre demasiado largo.',
        token: 'Token demasiado largo.',
        NT_player: 'El identificador del jugador del equipo nacional debe ser máximo 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Contraseña demasiado corta.',
        age_min: 'La edad mínima debe ser superior a 17.',
        age_max: 'La edad máxima debe ser superior a 17.',
        native_country_id: 'El identificador de pais debe ser superior a 0.',
        htms_min: 'El HTMS mínimo debe ser un número positivo.',
        htms_max: 'El HTMS máximo debe ser un número positivo.',
        htms28_min: 'El HTMS28 mínimo debe ser superior a -12.000.',
        htms28_max: 'El HTMS28 máximo debe ser superior a -12.000.',
        tsi_min: 'El TSI mínimo debe ser un número positivo.',
        tsi_max: 'El TSI máximo debe ser un número positivo.',
        salary_min: 'El salario mínimo debe ser un número positivo.',
        salary_max: 'El salario máximo debe ser un número positivo.',
        price_min: 'El precio mínimo debe ser un número positivo.',
        price_max: 'El precio máximo debe ser un número positivo.',
        NT_player: 'El identificador del jugador del equipo nacional debe ser mínimo 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'El email debe ser una dirección válida de correo.'
      },
      url: {
        url: 'El \'URL\' parámetro debe contener una dirección válida.'
      },
      in: {
        endpoint: 'El \'endpoint\' parámetro debe ser válido.',
        form_min: 'El valor mínimo de la forma tiene que ser entre 1 (\'@:player.levels.skill[1]\') y 8 (\'@:player.levels.skill[8]\').',
        form_max: 'El valor máximo de la forma tiene que ser entre 1 (\'@:player.levels.skill[1]\') y 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'El valor mínimo de carácter tiene que ser entre 0 (\'@:player.levels.agreeability[0]\') y 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'El valor máximo de carácter tiene que ser entre 0 (\'@:player.levels.agreeability[0]\') y 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'El valor mínimo de agresividad tiene que ser entre 0 (\'@:player.levels.aggressiveness[0]\') y 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'El valor máximo de agresividad tiene que ser entre 0 (\'@:player.levels.aggressiveness[0]\') y 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'El valor mínimo de honestidad tiene que ser entre 0 (\'@:player.levels.honesty[0]\') y 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'El valor máximo de honestidad tiene que ser entre 0 (\'@:player.levels.honesty[0]\') y 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'El valor mínimo de experiencia tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'El valor máximo de experiencia tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'El valor mínimo de liderazgo tiene que ser entre 1 (\'@:player.levels.skill[1]\') y 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'El valor máximo de liderazgo tiene que ser entre 1 (\'@:player.levels.skill[1]\') y 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'El valor mínimo de resistencia tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'El valor máximo de resistencia tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'El valor mínimo de portería tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'El valor máximo de portería tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'El valor mínimo de jugadas tiene que ser entre  0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'El valor máximo de jugadas tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'El valor mínimo de anotación tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'El valor máximo de anotación tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'El valor mínimo de pases tiene que ser entre  0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'El valor máximo de pases tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'El valor mínimo de lateral tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'El valor máximo de lateral tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'El valor mínimo de defensa tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'El valor máximo de defensa tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'El valor mínimo de balón parado tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'El valor máximo de balón parado tiene que ser entre 0 (\'@:player.levels.skill[0]\') y 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'El usuario no existe.'
      },
      boolean: {
        promoted: 'El \'promoted\' parámetro tiene que ser verdadero o falso.',
        bookmarked: 'El \'bookmarked\' parámetro tiene que ser verdadero o falso.',
        ignored: 'El \'ignored\' parámetro tiene que ser verdadero o falso.',
        notifications_email: 'El \'notifications_email\' parámetro tiene que ser verdadero o falso.',
        notifications_desktop: 'El \'notifications_desktop\' parámetro tiene que ser verdadero o falso.'
      },
      array: {
        specialties: 'Las especialidades deben contener una tabla de valores.'
      }
    },
    unknown: {
      general: 'Ha ocurrido un error inesperado. Por favor recarga la página.'
    },
    network: {
      general: 'Estas experimentando problemas de conexión.',
      maintenance: 'Sistema en mantenimiento, inténtalo en unos minutos.'
    },
    denied: {
      access: 'Acceso denegado.'
    },
    expired: {
      token: 'El token de autenticación ha caducado.',
      session: 'El usuario no esta validado.'
    },
    failed: {
      registration: 'Error al completar el registro.',
      user_details: 'Error al cargar los detalles de usuario.',
      player_details: 'Error al cargar los detalles del jugador.',
      filter_fetch: 'Error al buscar nuevos jugadores.',
      password_update: 'La contraseña no se ha actualizado.',
      chpp_connection: 'Error al iniciar la conexión CHPP.',
      chpp_authorization: 'Error al empezar la autroización CHPP.'
    },
    invalid: {
      credentials: 'Credenciales inválidas.',
      token: 'El token de autenticación es erroneo.',
      request: 'Solicitud inválida.'
    },
    limit: {
      filters: 'Has excedido el máximo de filtros que puedes crear.'
    },
    missing: {
      token: 'Falta el token de autenticación.'
    },
    conflict: {
      username: 'El usuario ya existe en el sistema.',
      username_password: 'El usuario y/o email ya existe en el sistema.'
    }
  }
}
